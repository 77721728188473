.editor-content p .img-responsive {
    height: 100%;
}
.editor-content .row {
    margin-top: 30px;
    margin-bottom: 30px;
}
.gallery-thumbs img {
    width: 100px;
    height: auto;
}

@media (max-width: 480px) {
    .editor-content .row {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .editor-content p {
        margin-bottom: 5px;
    }
}
@media (min-width: 768px) {
    .section-title .box {
        flex: 1;
    }
    .section-title .section-subtitle {
        flex: 2;
    }
}